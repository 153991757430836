import { NextRouter } from 'next/router';
import qs from 'qs';
import { CREATE_AVATAR_PAGE, MAIN_PAGE, PROCESS_PAGE } from './CONST';

const assetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX;
const rootAddress = process.env.NEXT_PUBLIC_ROOT_ADDRESS;

export const routeWithParamAs = (router: NextRouter, url: string, toLanguage?: string) => {
    // console.log('routeWithParamAs')
    // console.log(rootAddress);
    // console.log(assetPrefix);
    // console.log(url);
    // console.log(`${rootAddress}${assetPrefix}${url}`)

    router.push(url, `${rootAddress}${assetPrefix}${url}`, {
        ...(toLanguage ? { locale: toLanguage } : { shallow: true }),
    });
};

export const redirectToUsingMode = (mode: string | null | undefined, query: { [key: string]: string } = {}) => {
    if (!mode) return `${MAIN_PAGE}?${qs.stringify(query)}`;

    const splits = mode.split('.');

    let page = MAIN_PAGE;
    switch (splits[0]) {
        case CREATE_AVATAR_PAGE.substr(1):
            page = CREATE_AVATAR_PAGE;
            break;
        case PROCESS_PAGE.substr(1):
            page = PROCESS_PAGE;
            break;
        default:
            break;
    }

    return `${page}?${qs.stringify(query)}`;
};

export const getRedirectionKeyFromMCUrl = (mcUrl: string): string | null => {
    const splits = mcUrl ? mcUrl.split('/') : null;
    return splits && splits.length > 0 ? splits[splits.length - 1] : null;
};
