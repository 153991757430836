import * as _ from 'lodash';
import { GetServerSidePropsContext } from 'next';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoLoading from '../components/logo-loading/logo-loading';
import { crudGetListReqIdAction, crudGetOneReqIdAction } from '../redux/actions/fetchActionCreator';
import {
    resolvedAvatarActionCreator,
    resolvedCurrentReferencingActionCreator,
    resolvedMCActionCreator,
    resolvedTokenWithRolesActionCreator,
} from '../redux/actions/resourceActionCreator';
import { resolvedTokenAction } from '../redux/actions/securityActionCreator';
import { resolvedQuery } from '../redux/actions/uiActionCreator';
import { GlobalState } from '../redux/reducers';
import { getAvatar } from '../redux/selectors/avatar';
import { getCurrentReferencing } from '../redux/selectors/currentReferencing';
import { getMC } from '../redux/selectors/mc';
import { wrapper } from '../redux/store';
import requestIdGenerator from '../services/requestIdGenerator';
import getRequestIdGenerator from '../services/requestIdGenerator';
import Security from '../services/security';
import {
    AVATARS,
    CREATE_AVATAR_PAGE,
    FINISH_PAGE,
    GROUP_METADATA_AVATAR_TYPES,
    MAIN_PAGE,
    MCS_CROSS_STOCK,
    NODE_TYPE_END,
    NODE_TYPE_END_FAILED,
    PROCESS_PAGE,
    TOKEN_WITH_ROLES,
} from '../services/utils/CONST';
import { getLocale } from '../services/utils/get-translates';
import { extractIdFromURI, getResourceURIFROMAnotherURIWithoutId } from '../services/utils/ids';
import { redirectToUsingMode, routeWithParamAs } from '../services/utils/route';
import { Avatar, CurrentReferencing, MC, SuccessPayload } from '../services/utils/types';

const totalRequest = 6;

interface Props {
    tokenFromRequest: string;
    redirectTo: string;
    redirectionKey: string;
}

const Index = ({ redirectionKey, redirectTo, tokenFromRequest }: Props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const tCommon = useTranslations('common');
    const mc: MC | null = useSelector((state: GlobalState) => getMC(state.resources.mcUriFromUrl)(state));
    const avatar: Avatar | null = useSelector((state: GlobalState) => getAvatar(state));
    const currentReferencing: CurrentReferencing | null = useSelector((state: GlobalState) =>
        getCurrentReferencing(state)
    );
    const [requestCount, setRequestCount] = useState<number>(0);

    const getAvatarRequest = (avatarId: string, callback: any) => {
        setRequestCount((previousCount) => previousCount + 1);
        dispatch(
            crudGetListReqIdAction.fn(
                getRequestIdGenerator().gen(),
                AVATARS,
                { page: 1, perPage: 1 },
                { field: 'id', order: 'asc' },
                {
                    id: avatarId,
                    serialization_groups: [
                        'read_meta',
                        'read_meta_group_metadata_avatar_type',
                        'read_company',
                        'read_avatar_current_referencing',
                        'read_avatar_current_referencing_nodes',
                        'avatar_files',
                    ],
                },
                {
                    onSuccess: (payload) => {
                        setRequestCount((previousCount) => previousCount + 1);
                        callback(payload);
                    },
                    onFailure: () => {
                        enqueueSnackbar(tCommon('errorMessages.avatarNotFound'), {
                            variant: 'error',
                        });
                    },
                }
            )
        );
    };

    const getMc = (callback: any) => {
        setRequestCount((previousCount) => previousCount + 1);
        dispatch(
            crudGetListReqIdAction.fn(
                getRequestIdGenerator().gen(),
                MCS_CROSS_STOCK,
                { page: 1, perPage: 10 },
                { field: 'id', order: 'asc' },
                {
                    'yourls.short': redirectionKey,
                },
                {
                    onSuccess: (payload) => {
                        setRequestCount((previousCount) => previousCount + 1);
                        callback(payload);
                    },
                    onFailure: (payload) => {
                        enqueueSnackbar(tCommon('errorMessages.mcNotFound'), {
                            variant: 'warning',
                        });
                    },
                }
            )
        );
    };

    const getTokenWithRolesRequest = (avatarUri: string) => {
        dispatch(
            crudGetOneReqIdAction.fn(
                requestIdGenerator().gen(),
                GROUP_METADATA_AVATAR_TYPES,
                getResourceURIFROMAnotherURIWithoutId(avatarUri, TOKEN_WITH_ROLES),
                {
                    onSuccess: async ({ data }: { data: any }) => {
                        setRequestCount((previousCount) => previousCount + 1);
                        const { token, refresh_token: refreshToken } = data;
                        dispatch(resolvedTokenWithRolesActionCreator.fn({ token, refreshToken }));
                    },
                }
            )
        );
    };

    useEffect(() => {
        if (tokenFromRequest) {
            dispatch(resolvedTokenAction.fn(tokenFromRequest));
            dispatch(resolvedQuery.fn({ redirectionKey: redirectionKey }));
            getMc((payload: SuccessPayload) => {
                if (payload && payload.total === 1) {
                    const mc: MC = payload.data[0];
                    if (!mc.avatar) {
                        routeWithParamAs(router, `${CREATE_AVATAR_PAGE}?${qs.stringify(router.query)}`);
                    } else {
                        dispatch(resolvedMCActionCreator.fn(mc, { isMCUrl: true, isCreateAvatar: true }));
                    }
                }
            });
        } else {
            enqueueSnackbar(tCommon('errorMessages.mcNotFound'), {
                variant: 'error',
            });
        }
    }, []);

    useEffect(() => {
        if (mc) {
            setRequestCount((previousCount) => previousCount + 1);
            getAvatarRequest(extractIdFromURI(mc.avatar), (payload: SuccessPayload) => {
                if (payload.data.length > 0) {
                    const avatar: Avatar = payload.data[0];
                    dispatch(resolvedAvatarActionCreator.fn(avatar));
                    if ('currentReferencing' in avatar)
                        dispatch(resolvedCurrentReferencingActionCreator.fn(avatar.currentReferencing));
                }
            });
            getTokenWithRolesRequest(mc['@id']);
        }
    }, [mc]);

    useEffect(() => {
        if (currentReferencing) {
            if (currentReferencing.standardReferencing?.runner?.node?.nextNodes.length === 0) {
                const nodeTypes = currentReferencing.standardReferencing.runner.node.nodeTypes;
                if (_.includes(nodeTypes, NODE_TYPE_END_FAILED) && _.includes(nodeTypes, NODE_TYPE_END)) {
                    routeWithParamAs(router, `${FINISH_PAGE}?${qs.stringify(router.query)}`);
                } else {
                    routeWithParamAs(router, `${PROCESS_PAGE}?${qs.stringify(router.query)}`);
                }
            } else {
                routeWithParamAs(router, `${MAIN_PAGE}?${qs.stringify(router.query)}`);
            }
        }
    }, [currentReferencing]);

    return <LogoLoading value={requestCount} total={totalRequest} />;
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context: GetServerSidePropsContext) => {
    const { redirection_key, mode } = context.query;

    // @ts-ignore
    let tokenFromRequest: any = await Security.auth(context, redirection_key);

    if (
        (typeof tokenFromRequest === 'object' && 'status' in tokenFromRequest && 'statusText' in tokenFromRequest) ||
        typeof tokenFromRequest !== 'string'
    ) {
        tokenFromRequest = null;
    }

    const object = { ...context.query };
    delete object.mode;

    // @ts-ignore
    const redirectTo = redirectToUsingMode(mode, { ...object });

    return {
        props: {
            tokenFromRequest,
            redirectionKey: redirection_key,
            redirectTo,
            messages: {
                ...require(`../messages/common/${getLocale(context)}.json`),
            },
        },
    };
});
