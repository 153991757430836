import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import SvgIcon from '@material-ui/icons/CommentOutlined';
import { useTranslations } from 'next-intl';
import React from 'react';
import logo from '../../public/images/rikutec-logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '50vh',
        transform: 'translateY(-50%)',
        textAlign: 'center',
    },
}));

interface Props {
    value: number;
    total: number;
}

const LogoLoading = ({ value, total }: Props) => {
    const classes = useStyles();
    const tCommon = useTranslations('common');

    return (
        <div className={classes.root} data-testid='initialLoading'>
            <div style={{ marginBottom: 32 }}>
                <SvgIcon
                    component={logo}
                    viewBox='0 0 236 80'
                    style={{ width: 236, height: 80, marginBottom: 20, margin: 'auto' }}
                />
            </div>

            <Box style={{ position: 'relative', display: 'inline-flex', marginBottom: 15 }}>
                <CircularProgress
                    variant='determinate'
                    value={(value * 100) / total}
                    size={70}
                    thickness={5}
                    style={{ color: '#2764AE' }}
                />
                <Box
                    style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant='body1' component='div'>{`${Math.round((value * 100) / total)}%`}</Typography>
                </Box>
            </Box>

            <Grid container direction={'column'} style={{ ...(value === total ? {} : { color: '#E4E5EB' }) }}>
                <Grid item>
                    <div>{tCommon('texts.wait')}</div>
                </Grid>
                <Grid item>
                    <div>{tCommon('texts.interfaceConfig')}</div>
                </Grid>
            </Grid>
        </div>
    );
};

export default LogoLoading;
